import "./App.css";
import logo from "./images/Logo.svg";
import searchIcon from "./images/search-icon.svg";
import mombipay from "./images/mobipay-mobiles.png";
import angleNextIcon from "./images/angle-next-icon.svg";
import anglePrevIcon from "./images/angle-prev-icon.svg";
import twentyfour from "./images/24.png";
import twentysix from "./images/26.png";
import twentyfive from "./images/25.png";
import providerCard1 from "./images/provider_card1.png";
import providerCard2 from "./images/provider_card2.png";
import providerCard3 from "./images/provider_card3.png";
import cigar_club from "./images/cigar_club.png";
import "slick-carousel/slick/slick.css";
import zaysha from "./images/zaysha.png";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import soghata from "./images/soghats_logo 1.png";
import dukandar from "./images/dukandar_logo 1.png";
import map from "./images/Container.png";
import bookkaru from "./images/Bookkkaru Logo Blue Varient 1.png";
import phone from "./images/phone.png";
import mail from "./images/mail.png";
import bag from "./images/bag.png";
import { useState } from "react";

function App() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  let NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <img
        src={angleNextIcon}
        alt=""
        className={className}
        onClick={onClick}
        style={style}
      />
    );
  };

  let PreviousArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <img
        src={anglePrevIcon}
        alt=""
        className={className}
        onClick={onClick}
        style={style}
      />
    );
  };

  const settings = {
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={"mobipay-homepage"}>
      <div className={`navbar ${dropdownOpen ? "open" : ""}`}>
        <div className="navbar-header">
          <img className={"logo"} src={logo} alt={"not found"} />
          <button className="navbar-toggler" onClick={toggleDropdown}>
            <svg height={20} fill="currentColor" viewBox="0 0 448 512">
              <path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z" />
            </svg>
          </button>
        </div>
        <div className="navbar-content">
          <span className={"nav-links active"}>Home</span>
          <span className={"nav-links "}>About Us</span>
          <span className={"nav-links "}>Services</span>
          <span className={"nav-links "}>Affiliate Companies</span>
          <span className={"nav-links "}>Careers</span>
          <span className={"nav-links "}>Newsroom</span>
          <button className={"partner-btn"}>
            Partner with us{" "}
            <svg fill="currentColor" height={12} viewBox="0 0 448 512">
              <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
            </svg>
          </button>
          <button className="search-btn">
            <img src={searchIcon} alt="" />
          </button>
        </div>
      </div>

      <div className={"main-hero-section"}>
        <div className={"main-hero-conainer-first"}>
          <div className={"main-hero-heading"}>
            Seamless Payment Solutions Fast Transactions, Secure Payments, and
            Multi-Platform Support.
          </div>
          <button className={"learn-btn"}>
            Learn More{" "}
            <svg fill="currentColor" height={12} viewBox="0 0 448 512">
              <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
            </svg>
          </button>
        </div>
        <div className={"main-hero-conainer-second"}>
          <img src={mombipay} alt="" />
        </div>
      </div>

      <div className={"description-div"}>
        <div className={"description-text"}>
          Mobipay is a cutting-edge <span>payment gateway solution</span>{" "}
          designed to streamline online transactions for businesses of all
          sizes. With a focus on security and user-friendliness, Mobipay enables
          fast and reliable payments across multiple platforms. Committed to
          innovation, <span>Mobipay</span> aims to enhance the customer
          experience while ensuring seamless integration with various e-commerce
          systems.
        </div>
        <div className={"descriptions-cards"}>
          <div className={"description-card"}>
            <img src={twentyfour} />
          </div>
          <div className={"description-card"}>
            <img src={twentyfive} />
          </div>
          <div className={"description-card"}>
            <img src={twentysix} />
          </div>
        </div>
      </div>

      <div className={"trusted-by"}>
        <div className={"trust-heading"}>Trusted By</div>
        <div className="slider-container">
          <Slider {...settings}>
            <div className="slider-item">
              <img src={bookkaru} alt="" />
            </div>
            <div className="slider-item">
              <img src={cigar_club} alt="" />
            </div>
            <div className="slider-item">
              <img src={dukandar} alt="" />
            </div>
            <div className="slider-item">
              <img src={zaysha} alt="" />
            </div>
            <div className="slider-item">
              <img src={soghata} alt="" />
            </div>
          </Slider>
        </div>
      </div>

      <div className={"provide-div"}>
        <div className={"provide-text"}>
          <div className={"provide_heading"}>What we Provide</div>
          Mobipay provides a comprehensive suite of payment solutions, including
          seamless <span>online payment</span>
          processing, secure mobile transactions, and customizable invoicing
          options. Our platform supports multiple payment methods, ensuring
          convenience for businesses and their customers. Additionally, we offer
          robust API integration, detailed reporting tools, and dedicated
          customer support to enhance user experience and operational
          efficiency. With <span>Mobipay</span>, businesses can streamline their
          payment processes and focus on growth.
          <button class="learn-btn">
            Learn More{" "}
            <svg fill="currentColor" height={12} viewBox="0 0 448 512">
              <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
            </svg>
          </button>
        </div>
        <div className={"provider-cards"}>
          <div className={"provider-card"}>
            <img src={providerCard1} alt="" />
          </div>
          <div className={"provider-card"}>
            <img src={providerCard2} alt="" />
          </div>
          <div className={"provider-card"}>
            <img src={providerCard3} alt="" />
          </div>
          <div className={"provider-card"}>
            <img src={providerCard1} alt="" />
          </div>
        </div>
      </div>

      <div className={"affeliate-section"}>
        <div className={"affeliate-heading"}>Affiliate Companies</div>
        <div className={"affeliate-text"}>
          Mobipay partners with various affiliate companies to enhance its
          services and broaden its market reach.
        </div>
        <div className={"affeliate-cards"}>
          <div className={"affeliate-card"}>
            <img src={bookkaru} alt="" />
          </div>
          <div className={"affeliate-card"}>
            <img src={bookkaru} alt="" />
          </div>
          <div className={"affeliate-card"}>
            <img src={bookkaru} alt="" />
          </div>
          <div className={"affeliate-card"}>
            <img src={bookkaru} alt="" />
          </div>
          <div className={"affeliate-card"}>
            <img src={bookkaru} alt="" />
          </div>
        </div>
      </div>

      <div className={"contact-us-section"}>
        <div className={"contact-us-left"}>
          <div className={"contact-heading"}>Get in Touch with Us</div>
          <div className={"contact-detail-section"}>
            <img src={map} alt="" />
            <div className={"contact-detail-sub"}>
              <span>Address</span>
              <div>Garden Heights Garden Town,Lahore,Punjab</div>
            </div>
          </div>
          <div className={"contact-detail-section"}>
            <img src={phone} />
            <div className={"contact-detail-sub"}>
              <span>Phone</span>
              <div>+923111222629</div>
            </div>
          </div>
          <div className={"contact-detail-section"}>
            <img src={mail} />
            <div className={"contact-detail-sub"}>
              <span>Partnership and General Inquiries</span>
              <div>contactus@mobipay.pk</div>
            </div>
          </div>
          <div className={"contact-detail-section"}>
            <img src={bag} />
            <div className={"contact-detail-sub"}>
              <span>Apply Now</span>
              <div>careers@mobipay.pk</div>
            </div>
          </div>
        </div>
        <div className={"contact-us-right"}>
          <div className={"contact-form"}>
            <div className={"inputs-container-group"}>
              <div className={"input-container"}>
                <label>Name:</label>
                <input placeholder={"Enter your Name"} />
              </div>
              <div className={"input-container"}>
                <label>Email:</label>
                <input placeholder={"Enter your Email"} />
              </div>
            </div>
            <div className={"input-container1"}>
              <label>Subject/Query</label>
              <input placeholder={"Enter your Query"} />
            </div>
            <div className={"input-container1"}>
              <label>Your Message</label>
              <textarea rows={6} />
            </div>
            <button className={"sent-btn"}>Sent</button>
          </div>
        </div>
      </div>

      <div className={"footer"}>
        <div className="footer-logp-section">
          <img src={logo} alt="" />
        </div>
        <div className={"footer-main-link-section"}>
          <div className={"footer-link-section"}>
            <span className={"footer-bold"}>About us</span>
            <span className={"footer-link"}>Careers</span>
          </div>
          <div className={"footer-link-section"}>
            <span className={"footer-bold"}>Contact us</span>
            <span className={"footer-link"}>03111222629</span>
            <span className={"footer-link"}>contactus@mobipay.pk</span>
          </div>
          <div className={"footer-link-section"}>
            <span className={"footer-bold"}>Affilates Companies</span>
          </div>
        </div>
      </div>

      <div className={"copyright-section"}>Copyright | All Right Reserved</div>
    </div>
  );
}

export default App;
